import React from "react"

const StickerLeft = ({color, className}) => {
  return (
    <svg className={className} width={26} height={183} viewBox="0 0 26 183" fill="none" preserveAspectRatio="none">
      <path
        d="M14.173 177.248c.966 3.504 2.8 5.785 4.621 5.752l6.706-.015V0l-8 .012-2.763.004c-1.511.034-2.79 1.632-3.41 4.303-.621 2.636-.489 5.987.35 8.88l2.818 9.781-11.64 5.78c-1.608.799-2.707 3.486-2.84 6.973-.137 3.454.718 7.111 2.21 9.39l7.083 10.839-1.583 2.517c-1.032 1.633-1.554 4.303-1.422 7.212.131 2.909.915 5.733 2.118 7.638l4.233 6.755-7.066 4.811C3.93 86.018 2.939 89.25 3.066 93.06c.143 3.81 1.377 7.417 3.137 9.152l9.7 9.513-5.79 13.129c-.917 2.075-1.218 5.086-.783 8.131.425 3.028 1.513 5.665 2.914 7.078l9.253 9.29-7.127 17.62c-1.09 2.706-1.172 6.754-.197 10.275z"
        fill={color}
      />
    </svg>
  )
}

export default StickerLeft
