import React from "react"

const StickerRight = ({color, className}) => {
  return (
    <svg className={className} width={34} height={183} viewBox="0 0 34 183" fill="none" preserveAspectRatio="none">
      <path
        d="M32.219 63.522c1.145-1.428 1.818-4.029 1.779-7.004a24.2 24.2 0 00-.061-1.341c-.208-2.517-.887-4.914-1.881-6.648l-8.888-15.406 3.152-6.408c1.042-2.142 1.368-5.457.859-8.721-.515-3.248-3.361-7.175-3.361-7.175S18.305 1.97 12.5.498C11.056.133 9.278-.016 8.718.001L0 .026v182.915l10.418.059 16.517-.059c1.39-.016 2.596-1.725 3.258-4.054.661-2.329.717-5.338.122-8.144l-3.36-16.152 1.472-.016c1.667-.016 3.055-2.005 3.575-5.116.521-3.11.106-6.851-1.078-9.69l-5.537-13.297 5.815-7.563c1.318-1.733 1.95-4.879 1.617-8.5-.322-3.485-1.552-6.613-3.173-8.127l-3.476-3.214 3.169-4.351c1.105-1.53 1.72-4.148 1.635-7.089-.076-2.924-.819-5.848-2.013-7.855l-4.175-7.056 7.433-9.195z"
        fill={color}
      />
    </svg>
  )
}

export default StickerRight
