import React from "react"

const AdventureHeader = ({title}) => (
  <section className={"adventure-header"}>
    <div className="container">
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
    </div>
  </section>
)

export default AdventureHeader
