import React from 'react';

const BackgroundImg = ({source, alt, title, classe, width, height, crop}) => {

    let src = source.replace('https://funky.buddybuddy.io/wp-content/uploads/', 'https://cdn.funkyveggie.fr/wp-content/uploads/');

    let additionnal = '';

    if(crop) {
        additionnal = '&crop=smart';
    }
    
    if(width !== null) {
        additionnal = additionnal + '&width='+width;
    }

    if(height !== null) {
        additionnal = additionnal + '&height='+height;
    }

    return (
        <div style={{backgroundImage: `url(${src+'?format=webp'+additionnal})`}} role="img" aria-label={alt} alt={alt} title={title} className={classe}></div>
    )
}

export default BackgroundImg;

BackgroundImg.defaultProps = {
    source: null,
    alt: null,
    title: null,
    classe: null,
    width: null,
    height: null,
    crop: null,
}