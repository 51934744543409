import { Link } from "gatsby"
import Open from "../../images/icons/open.svg"
import { ROUTES } from "../../../constants.js"
import React from "react"
import Smile from "../../images/icons/smile.svg"
import normalizePath from "../../helpers/normalizePath.js"

const Join = ({ title, text, buttonTitle, buttonLink, location }) => (
  <section className={"section-join"}>
    <div className="container">
      {location && normalizePath(ROUTES.ADVENTURE) ? (
        <img src={Smile} alt="" />
      ) : (
        <img src={Open} alt="" />
      )}
      <div className={"side"}>
        <h2>{title ? title : `Envie de rejoindre l'aventure ?`}</h2>
        <p>
          {text ? text : `Vous aussi, vous voulez rendre le monde plus Funky ? Nos produits vous font craquer ? Alors on est peut-être fait pour s’entendre !`}
        </p>
        <Link to={buttonLink ? buttonLink : '/'} title={buttonTitle} className={"link link--black"}>
          {buttonTitle ? buttonTitle : `Nous contacter` }
        </Link>
      </div>
    </div>
  </section>
)

export default Join
