import { Link } from "gatsby"
import React from "react"

const Univers = ({ titre, description, category }) => (
  <section className={"adventure-univers"}>
    <div className="container">
      <div className={"col col__title"}>
        <h2 dangerouslySetInnerHTML={{ __html: titre }} />
      </div>
      <div className={"col col__description"}>{description}</div>
      {!!category &&
        category.map((item, i) => (
          <div className={`col col__podcast col__box${i}`} key={i}>
            <img
              className={"image"}
              src={item.icon.localFile.publicURL}
              alt=""
            />
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <Link
              to={item.linkType === 'product' ? item.buttonLinkProduct.uri : item.buttonLink.link}
              title={item.buttonTitle}
              className={"link link--white"}
            >
              {item.buttonTitle}
            </Link>
          </div>
        ))}
    </div>
  </section>
)

export default Univers
