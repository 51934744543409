import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"

import TitleIcon from "../../decoration/title-icon.js"
import UserBook from "../../../images/titles/user-book.svg"
import useWindowSize from "../../../helpers/hooks/useWindowSize.js"

import BackgroundImage from '../../../helpers/backgroundImage'

const Team = ({ title, teamMembers }) => {
  const [mobileTeamMembers, setMobileTeamMembers] = useState(teamMembers)

  useEffect(() => {
    if (teamMembers.length > 2) {
      let first = teamMembers[2]
      let second = teamMembers[0]
      let third = teamMembers[1]
      let reste = teamMembers.slice(3, teamMembers.length)
      setMobileTeamMembers([first, second, third, ...reste])
    }
  }, [teamMembers])

  const sizes = useWindowSize()
  return (
    <section className={`adventure-team${sizes.isMobile ? "-mobile" : ""}`}>
      <div className="container">
        {!!title && (
          <h2>
            {title}
            <TitleIcon color="#ff6b00" icon={<img src={UserBook} alt="" />} />
          </h2>
        )}

        {sizes.isMobile ? (
          <Swiper
            className={"adventure-team-mobile__slider"}
            slidesPerView={1.4}
            spaceBetween={16}
            breakpoints={{
              768: {
                slidesPerView: 2.5,
                spaceBetweenSlides: 24,
              },
              540: {
                slidesPerView: 1.5,
                spaceBetweenSlides: 24,
              },
            }}
          >
            {mobileTeamMembers.map(({ role, photo, name, color, icon }, i) => (
              <SwiperSlide tag="div" key={i}>
                <div
                  className={"adventure-team-mobile__member"}
                  style={{ backgroundColor: color }}
                >
                  {!!photo &&
                    !!photo.sourceUrl && (
                      <BackgroundImage 
                        source={photo.sourceUrl}
                        classe='adventure-team-mobile__background'
                        alt={photo.altText}
                      />
                    )}
                  {/* {!!icon && !!icon.localFile && !!icon.localFile.publicURL && (
                    <Image
                      className={"adventure-team-mobile__icon"}
                      fluid={icon.localFile.publicURL}
                      alt={icon.altText}
                    />
                  )} */}
                </div>
                <div className={"adventure-team-mobile__description"}>
                  <span className={"adventure-team-mobile__name"}>{name}</span>
                  <span className={"adventure-team-mobile__role"}>{role}</span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <ul className={"adventure-team__members"}>
            {teamMembers.map(({ role, photo, name, color, icon }, i) => (
              <li
                className={"adventure-team__member"}
                style={{ backgroundColor: color }}
                key={i}
              >
                  {!!photo && !!photo.sourceUrl && (
                    <BackgroundImage 
                      alt={name + ' - ' + role + ' - ' + 'Funkyveggie'}
                      source={photo.sourceUrl}
                      classe='adventure-team__background'
                    />
                  )}
                  
                {!!icon && !!icon.localFile && !!icon.localFile.publicURL && (
                  <img
                    className={"adventure-team__icon"}
                    src={icon.localFile.publicURL}
                    alt={icon.altText}
                  />
                )}
                <div
                  className={"adventure-team__description"}
                  style={{ backgroundColor: color }}
                >
                  <span className={"adventure-team__name"}>{name}</span>
                  <span className={"adventure-team__role"}>{role}</span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  )
}

export default Team
