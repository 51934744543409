import {
  Accordion,
  AccordionItem,
} from "../../../components/accordion/accordion.js"

import React from "react"
import ThumbIcon from "../../../images/titles/thumb.svg"
import TitleIcon from "../../decoration/title-icon.js"
import scrollTo from "../../../helpers/scrollToEl.js"
import useWindowSize from "../../../helpers/hooks/useWindowSize.js"

const Commitment = ({ title, sections }) => {
  const { isMobile } = useWindowSize()

  return (
    <section className={"adventure-commitment"}>
      <div className="container">
        <h2>
          {title}
          <TitleIcon color="#ff6b00" icon={<img src={ThumbIcon} alt="" />} />
        </h2>
        <Accordion
          additionalClass="adventure-accordion"
          itemClasses={["blue", "orange", "red"]}
          defaultIndex={0}
          allowClose={isMobile}
          onItemClick={i =>
            setTimeout(() => {
              scrollTo(
                document.querySelector(`.accordion__item:nth-child(${i + 1})`),
                120
              )
            }, 100)
          }
        >
          {!!sections &&
            sections.length > 0 &&
            sections.map((item, i) => (
              <AccordionItem label={item.shortTitle} index={i} key={i}>
                <div dangerouslySetInnerHTML={{ __html: item.text }} />
              </AccordionItem>
            ))}
        </Accordion>
      </div>
    </section>
  )
}

export default Commitment
