import Commitment from "../components/adventure/commitment/commitment.js"
import Header from "../components/adventure/header/header.js"
import Join from "../components/join/join.js"
import Layout from "../components/layout/layout"
import Origin from "../components/adventure/origin/origin.js"
import React from "react"
import SEO from "../components/seo.js"
import ScrollAnimation from "react-animate-on-scroll"
import Team from "../components/adventure/team/team.js"
import Univers from "../components/adventure/univers/univers.js"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

const Adventure = ({ data, location, pageContext }) => {
  const seo = pageContext.page.seo
  const {
    title,
    commitments,
    univers,
    joinsUs,
    origine,
    team,
  } = data.page.adventure_content;

  const desc = `La Boule, Cœur de Boule, L'apéro Boules et OUF!, des produits gourmands, 100% naturels et funky ! Vegan et sans gluten, et "sans rien de bizarre à l'intérieur" !`;

  const microDatas = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "funkyveggie.fr",
    "url": "https://www.funkyveggie.fr/",
    "description": seo.metaDesc,
    "logo": "https://www.funkyveggie.fr/logo.png",
  }

  return (
    <Layout location={location}>
      <SEO meta={seo} />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(microDatas)} 
        </script> 
      </Helmet>
      <div className={"page-adventure"}>
        <Header title={title} />
        <Origin {...origine} />
        <ScrollAnimation animateIn="fadeInBottom" duration={1}>
          <Commitment {...commitments} />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInBottom" duration={1}>
          <Univers {...univers} />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInBottom" duration={1}>
          <Team {...team} />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInBottom" duration={1}>
          <Join
            title={joinsUs.title}
            text={joinsUs.text}
            buttonTitle={joinsUs.buttonTitle}
            buttonLink={joinsUs.buttonLink}
            location={location}
          />
        </ScrollAnimation>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AdventurePageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      adventure_content {
        title
        commitments {
          title
          sections {
            color
            text
            title
            shortTitle
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
        univers {
          titre
          description
          category {
            title
            description
            icon {
              localFile {
                publicURL
              }
            }
            linkType
            buttonTitle
            buttonLink {
              link
            }
            buttonLinkProduct {
              ... on WpProduct {
                uri
              }
            }
          }
        }
        joinsUs {
          title
          text
          buttonTitle
          buttonLink
        }
        origine {
          title
          text
          subtitle
        }
        team {
          title
          teamMembers {
            role
            photo {
              title
              altText
              sourceUrl
            }
            name
            color
            icon {
              title
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

export default Adventure
