import React from "react"
import Wave from "../../decoration/wave.js"

const Origin = ({title, subtitle, text}) => (
  <section className={"adventure-origin"}>
    <Wave color="#FFFFFF" position="top" />
    <div className="container">
      <h1>
        {title}
      </h1>
      <h2>{subtitle}</h2>
      <div dangerouslySetInnerHTML={{ __html: text }}  />
    </div>
    <Wave color="#FFFFFF" position="bottom" />
  </section>
)

export default Origin
