import React from "react"
import StickerLeft from './sticker-left.js';
import StickerRight from './sticker-right.js';

const TitleIcon = ({ color, icon }) => (
  <span className='title-icon'>
      <StickerLeft className='left-slice' color={color} />
      <div style={{backgroundColor: color}} className='icon'>{icon}</div>
      <StickerRight className='right-slice' color={color} />
  </span>
)

export default TitleIcon
